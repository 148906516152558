/**
 * 🌱 These routes are public.
 * 🔓 Not required for authentication.
 * @type {string[]}
 */
export const publicRoutes = ["/", "/docs", "verify", "/check", "/dashboard/review-filter/demo", "/google-review-link-generator"];


/**
 * 🌱 These routes are used for the api.
 * 🔓 Not required for authentication.
 * @type {string[]}
 */
export const apiRoute = "/api";

/**
 * 🌱 These routes are used for authentication.
 * 🔓 Not required for authentication.
 * @type {string[]}
 */
export const authRoutes = [
  "/auth",
  "/register",
  "/auth-error",
  "/verify",
  "/reset",
  "/new-password",
  "/auth/signUp",
];

/**
 * 🌱 These routes are protected.
 * 🔒 Required authentication.
 * @type {string[]}
 */
export const protectedRoutes = ["/dashboard", "/dashboard/admin", "/dashboard/settings", "/dashboard/review-filter", "/dashboard/review-filter/success", "/claim", "/api"];

/**
 * 🌱 These routes are used for the check slug.
 * ✍️ Only type the prefix, with "/".
 * 🔓 Not required for authentication.
 * @type {string[]}
 */
export const checkRoutesPrefix = "/check";

/**
 * 🌱 These routes are used for the free review links.
 * ✍️ Only type the prefix, with "/".
 * 🔓 Not required for authentication.
 * @type {string[]}
 */
export const freeReviewLinkRoutesPrefix = "/rl";

/**
 * 🌱 These routes are used for the review slug.
 * ✍️ Only type the prefix, with "/".
 * 🔓 Not required for authentication
 * @type {string[]}
 */
export const reviewRoutesPrefix = "/review";

/**
 * 🌱 These prefix for API authentication routes.
 * ✍️ Routes that start with this prefix are used for API authentication purposes.
 * 🔓 Not required for authentication.
 * @type {string}
 */
export const apiAuthPrefix = "/api/auth";

/**
 * 🌱 The default redirect URL after logging in.
 * 🔓 Not required for authentication.
 * @type {string}
 */
export const DEFAULT_LOGIN_REDIRECT_URL = "/dashboard";
