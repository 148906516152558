'use client'

import Script from 'next/script'
import { usePathname } from 'next/navigation'

// Import route constants for consistency with middleware
import {
  apiRoute,
  checkRoutesPrefix,
  freeReviewLinkRoutesPrefix,
  authRoutes,
  protectedRoutes,
  publicRoutes,
} from "~/routes";

export function BrevoScript() {
  const pathname = usePathname()
  
  // Known routes check
  const isApiRoute = pathname.startsWith(apiRoute)
  const isCheckRoute = pathname.startsWith(checkRoutesPrefix)
  const isClaimRoute = pathname.startsWith('/claim')
  const isReviewRoute = pathname.startsWith('/review')
  const isFreeReviewLinkRoute = pathname.startsWith(freeReviewLinkRoutesPrefix)
  const isProtectedRoute = protectedRoutes.includes(pathname)
  const isPublicRoute = publicRoutes.includes(pathname)
  const isAuthRoute = authRoutes.includes(pathname)
  
  // Only render on known routes
  const isKnownRoute = 
    isApiRoute || 
    isCheckRoute || 
    isClaimRoute || 
    isReviewRoute || 
    isFreeReviewLinkRoute || 
    isProtectedRoute || 
    isPublicRoute || 
    isAuthRoute
  
  if (!isKnownRoute) {
    return null
  }
  
  return (
    <Script
      id="brevo-conversations"
      strategy="afterInteractive"
      src="/brevo-embed.js"
    />
  )
}
